<template>
  <v-container fluid>
    <v-row>
      <v-col md="2" class="text-right">
        <label for="name">Name</label>
      </v-col>
      <v-col md="10">
        <v-text-field
          color="cyan"
          v-model.trim="rolesDetail.name"
          dense
          filled
          flat
          solo
          label="Name"
          :rules="[validateRules.required(rolesDetail.name, 'Name')]"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  name: "Roles-Detail",
  mixins: [ValidationMixin],
  watch: {
    rolesDet: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$emit("updateRolesDetail", param);
      },
    },
  },
  data() {
    return {
      items: [],
      rolesDet: {
        name: null,
      },
    };
  },
  props: {
    rolesDetail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  mounted() {
    this.rolesDet = this.rolesDetail;
  },
};
</script>
