<template>
  <div>
    <v-container class="pt-0">
      <v-row>
        <v-col md="6">
          <h1 class="form-title my-0">Roles</h1>
        </v-col>
        <v-col md="2">
          <v-btn
            :disabled="dataLoading"
            class="mx-2 custom-bold-button float-right"
            color="cyan white--text"
            v-on:click="updateDialogConfirm({}, 'Add')"
          >
            <v-icon dark left>mdi-plus</v-icon> Create
          </v-btn>
        </v-col>
        <v-col md="3">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getRows"
            @keydown.tab="getRows"
          ></v-text-field>
        </v-col>
        <v-col md="1">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getRows"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <ListingTable
        :columnCount="3"
        :dataLoading.sync="dataLoading"
        :rowData.sync="rowData"
      >
        <template v-slot:thead>
          <thead>
            <tr>
              <th class="simple-table-th" width="200">#</th>
              <th class="simple-table-th">Name</th>
              <th class="simple-table-th" width="300">Action</th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody class="custom-border-bottom custom-border-top">
            <template v-if="rowData.length > 0">
              <tr :key="index" v-for="(row, index) in rowData">
                <td
                  link
                  class="simple-table-td"
                  v-on:click="updateDialogConfirm(row, 'Update')"
                >
                  {{ index + 1 }}
                </td>
                <td
                  link
                  class="simple-table-td"
                  v-on:click="updateDialogConfirm(row, 'Update')"
                >
                  {{ row.name }}
                </td>
                <td class="simple-table-td">
                  <v-btn
                    link
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    color="cyan white--text"
                    v-on:click="
                      $router.push(
                        getDefaultRoute('profile.userpermission', {
                          params: {
                            id: row.id,
                          },
                          query: { name: row.name },
                        })
                      )
                    "
                  >
                    Permission
                  </v-btn>
                </td>
              </tr>
            </template>
            <tr v-else-if="!dataLoading">
              <td colspan="5" class="py-5">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no roles at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </ListingTable>
    </v-container>
    <Dialog :commonDialog="updateDialog">
      <template v-slot:title> {{ rolestype }} Role </template>
      <template v-slot:body>
        <v-form
          ref="userAddUpdateRoleForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateRoleRecords"
        >
          <RolesDetail
            :rolesDetail.sync="rolesDetail"
            v-on:updateRolesDetail="updateUsersRolesDetail"
          ></RolesDetail>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          v-on:click="updateRoleRecords"
          color="cyan"
        >
          {{ rolestype }} Role
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="updateDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>
<script>
import {
  QUERY,
  CLEAR_ERROR,
  PATCH,
} from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import RolesDetail from "@/view/pages/profile/Roles-Detail.vue";
export default {
  name: "UserRoleTableTemplate",
  components: {
    ListingTable,
    RolesDetail,
    Dialog,
  },
  data() {
    return {
      roles: [],
      rowData: [],
      rolesDetail: new Object(),
      pageModule: "roles-listing",
      routeAPI: "roles",
      routeName: "profile.users",
      dataLoading: false,
      updateDialog: false,
      timeout: null,
      timeoutLimit: 500,
      formValid: true,
      formLoading: false,
      usersRoleDetailData: null,
      rolestype: "Add",
      filter: {
        search: null,
      },
    };
  },
  methods: {
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
      }
      this.getRows();
    },

    getRows() {
      const _this = this;
      let filter = {
        search: _this.filter.search || undefined,
      };
      clearTimeout(_this.timeout);
      _this.rowData = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "/roles",
            data: { filter },
          })
          .then(({ data }) => {
            _this.rowData = data;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },

    updateDialogConfirm(row, type) {
      this.rolesDetail = row;
      this.rolestype = type;
      this.updateDialog = true;
    },

    updateUsersRolesDetail(dataArr) {
      this.usersRoleDetailData = dataArr;
    },

    updateRoleRecords() {
      const _this = this;
      if (!_this.$refs.userAddUpdateRoleForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      const formData = {
        name: _this.usersRoleDetailData.name,
      };

      _this.$store
        .dispatch(PATCH, {
          url: "add_update_roles/" + (this.rolesDetail.id || ""),
          data: formData,
        })
        .then(() => {
          this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    this.getRows();
  },
};
</script>
